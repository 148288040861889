import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Container from '../components/container'

import style from './404.module.css'

const NotFoundPage = () => (
  <Layout>
    <SEO
      title='404 Page Not found'
      description='This page appears when a visitor tries to go to a page that does not exist. For Tweeker an ab and multivariate testing tool.'
    />
    <Header  />
    <Container>
      <h2 className={style.title}>404 Not Found</h2>
      <p className={style.details}>
        Whoops, sorry this page doesn't exist.
        Your best bet is to go to the{' '}
        <Link to='/'>homepage</Link> and try again from there.
      </p>
    </Container>
  </Layout>
)

export default NotFoundPage
